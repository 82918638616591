
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fetchUserData from './components/authService'
import "./assets/css/Auth.css"; // Import your CSS file for styling

const Login = ({
    updateIsLoading
  }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [prevUrl, setPrevUrl] = useState({});


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prevUrl = searchParams.get("prevUrl");
    setPrevUrl(prevUrl);

    const fetchData = async () => {
      const responseData = await fetchUserData();
      if (responseData) {

        localStorage.setItem("email", responseData.email);
        localStorage.setItem("family_name", responseData.family_name);
        localStorage.setItem("given_name", responseData.given_name);

        navigate(prevUrl || "/");
      }
    };

    fetchData();
  }, [navigate]);


  const handleLogin = async (e) => {
    e.preventDefault();
    updateIsLoading(true);
    try {
      const response = await fetch("https://dev.api.bigsisters.bc.ca/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email, password: password }),
      });

      updateIsLoading(false);

      console.log(response);
      if (response.ok) {
        const responseData = await response.json();

        localStorage.setItem("accessToken", responseData.token);
        localStorage.setItem("email", responseData.email);
        localStorage.setItem("family_name", responseData.family_name);
        localStorage.setItem("given_name", responseData.given_name);
        localStorage.setItem("token_expiry_time", responseData.token_expiry_time);


        navigate(prevUrl || "/");
      } else {
        // Handle failed login
        setError({
          message:
            "Your specified username and password failed to login. Please check your details and try again.",
        });
      }
    } catch (error) {
      setError({ message: `Login failed: ${error}` });
    }
  };

  return (
    <>
    <div className="form-box">
      <form onSubmit={handleLogin}>
        <div className="col-md-12">
          <h4 style={{ color: "#2dccd3" }}>Alternative Tickets Login</h4>
          {error?.message && <p className="form-error">{error?.message}</p>}
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="username">
              Username <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="password">
              Password <span className="required">*</span>
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </div>
        </div>

        <button className="btn btn-theme effect btn-md" type="submit" style={{width: "100%"}}>
            Login
          </button>
        </form></div>
      </>
  );
};

export default Login;