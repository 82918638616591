import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TicketThankYouPage from "./TicketThankYouPage";
import BlueSection from "./BlueSection";
import { ReactComponent as BackgroundSVG } from "../assets/img/background2.svg";
import quantityOptions from "./generateTicketService";


const ticketOptions = [{ value: "free-ticket", label: "Complimentary Ticket", cost: 0 },
  { value: "sponsor-ticket", label: "Ticket", cost: 400 },
{ value: "sponsor-table", label: "Table", cost: 4000 },
{ value: "sponsor-corporate", label: "Corporate Table", cost: 5000 },
{ value: "sponsor-ambassador", label: "Ambassador Table", cost: 10000 }];


class SponsorshipBuyTicketsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      selectedTicketQuantityOption: quantityOptions[0],
      selectedTicketOption: ticketOptions[0],
      payFees: false,
      firstName: "",
      ticketOptions: ticketOptions,
      ticketQuantityOptions: quantityOptions,
      registrationURL: "",
      isMobile: window.innerWidth < 992, // Set initial value based on window width
      isSuperMobile: window.innerWidth < 767 // Set initial value based on window width
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
    window.addEventListener("resize", this.updateIsMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth < 992, isSuperMobile:  window.innerWidth < 767});
  };

  updateFirstName = (firstName) => {
    this.setState({ firstName: firstName });
  };

  updateTicketOption = (option, identifier) => {

    if (identifier === "selectedTicketOption") {
      let selectedTicketQuantityOption = this.state.selectedTicketQuantityOption;
  
      if (option.value.includes("table") || option.value.includes("corporate") || option.value.includes("ambassador")) {
        selectedTicketQuantityOption = quantityOptions[0];
      }
  
      this.setState({ [identifier]: option,
        selectedTicketQuantityOption: selectedTicketQuantityOption

      });
    }else {
      this.setState({ [identifier]: option
      });
    }

    }

  updateRegistrationURL = (value) => {
    this.setState({ registrationURL: value });
  };

  render() {
    return (
      <>
        <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          >
            <BackgroundSVG />
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className={"row"}>
            <div className="col-lg-6 info">
             
             </div>

            <div className={"col-lg-6 info text-light donation-form"}>
                {this.state.hasDonated ? (
                  <TicketThankYouPage
                    selectedTicketQuantityOption={
                      this.state.selectedTicketQuantityOption
                    }
                    selectedTicketOption={this.state.selectedTicketOption}
                    registrationURL={this.state.registrationURL}
                    updateHasDonated={this.updateHasDonated}
                    firstName={this.state.firstName}
                  />
                ) : (
                  <StripeContainer
                    updateIsLoading={this.updateIsLoading}
                    updateHasDonated={this.updateHasDonated}
                    updateAmount={this.updateAmount}
                    payFees={this.state.payFees}
                    togglePayFees={this.togglePayFees}
                    updateFirstName={this.updateFirstName}
                    formType="SponsorshipBuyTickets"
                    ticketOptions={this.state.ticketOptions}
                    updateTicketOption={this.updateTicketOption}
                    selectedTicketOption={this.state.selectedTicketOption}
                    updateRegistrationURL={this.updateRegistrationURL}
                    selectedTicketQuantityOption={
                      this.state.selectedTicketQuantityOption
                    }
                    ticketQuantityOptions={this.state.ticketQuantityOptions}
                  />
                )}
              </div>




            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default SponsorshipBuyTicketsComponent;