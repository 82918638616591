import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";

import SponsorshipBuyTicketsComponent from './components/SponsorshipBuyTicketsComponent';
import LoginComponent from './components/LoginComponent';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SponsorshipBuyTicketsComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="*" element={<SponsorshipBuyTicketsComponent />} /> {/* Fallback route */}

      </Routes>
    </div>
  );
}

export default App;
