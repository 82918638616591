import React, { useState, useEffect } from 'react';
import '../assets/css/Navbar.css'; // Import your CSS file for styling
import Logo from '../assets/img/logo.png';
import MobileLogo from '../assets/img/mobile-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("accessToken")

  useEffect(() => {
    if (!token) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      navigate(`/login?${currentUrl.searchParams}`);
    }
  }, [token, location, navigate]);

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    if (isScrolled !== scrolled) {
      setScrolled(isScrolled);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem('accessToken', '');

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };

  return (
    <>
      <img className="mobile-logo" src={Logo} alt="Logo" />
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className='container'>
          <div className="logo">
            <img className="desktop-logo" src={Logo} alt="Logo" />
          </div>

          {token && (
          <a onClick={handleLogout} className="logout-btn">
            <FontAwesomeIcon icon={faSignOut} />&nbsp;
            <span className="shop-menu-ttl">Log Out</span>
          </a>
        )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;